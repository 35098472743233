import React, { useMemo } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { gql, useApolloClient } from '@apollo/client'
import styled from 'styled-components/macro';

import BackIcon from 'core/components/icons/BackIcon';
import BurgerIcon from 'core/components/icons/BurgerIcon';
import HomeIcon from 'core/components/icons/HomeIcon';

import { useSelectedOrderData } from 'core/components/SelectedOrderData';
import { queue } from 'core/components/queue'

const Title = styled.div`
  flex: 1;
  border-left: 1px solid #07572a;
  border-right: 1px solid #07572a;
  padding: 0 10px;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
`;

const LeftPart = styled.div`
  width: 50px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Setting = styled.div``;

const QUERY_WEEK_NUMBER = gql`
  query weekNumber($orderId: ID!) {
    getCustomerOrderWeek(orderId: $orderId)
  }
`

const Header = ({ className, title, back, backLink, backHome, home, onBack, withoutBurger, overrideTitle }) => {
  const router = useRouter();
  const { order } = useSelectedOrderData();

  const { id, currentOrderWeek } = order

  const goHome = () => {
    if (home) {
      router.push(`/?tab=${home}`);
    }
  };

  // const { data, loading } = useQuery(QUERY_WEEK_NUMBER, { variables: { orderId: order.id }, skip: !order.id })
  const [week, setWeek] = React.useState('...')
  const client = useApolloClient()

  React.useEffect(() => {
    const fn = async () => {
      const result = await client.query({ query: QUERY_WEEK_NUMBER, variables: { orderId: id }, fetchPolicy: 'cache-first' })
      const w = result.data?.getCustomerOrderWeek;
      if (w) {
        setWeek(`W${w}*`)
      }
    }
    if (currentOrderWeek) {
      setWeek(`W${currentOrderWeek}`)
    } else
      if (id) {
        queue.add(() => fn())
      }
  }, [id, client, currentOrderWeek])

  const goBack = () => {
    if (onBack) {
      onBack();
    } else if (backLink) {
      router.push(backLink);
    } else if (backHome) {
      goHome();
    } else {
      router.back();
    }
  };

  const showBack = useMemo(
    () => backLink || back || backHome || onBack,
    [backLink, back, backHome, onBack]
  );

  const titleToRender = React.useMemo(() => {
    if (!order?.id || overrideTitle) {
      return title;
    }
    const mealsNum = order?.items?.filter(item => item?.product?.category?.name === 'meals').length || 0;
    const customerName = order?.customer ? `${order.customer.firstName} ${order.customer.lastName}` : 'undefined';

    // const week = loading ? '' : `W${data?.getCustomerOrderWeek} `
    let extInfo = ''
    if (['picking','packing'].includes(home)) {
      const diet = order?.diets?.filter(d => d.id !== 'no-preference').map(d => d.title).join(', ') || '';
      if (diet) {
        extInfo += `D-${diet}`;
      }
      const coupon = order?.coupons?.map(c => c.code).join(', ') || '';
      if (coupon) {
        extInfo += ` C-${coupon}`;
      }
    }
    return `Order#${order.id} ${customerName} ${mealsNum} Meals ${week} ${extInfo}`;
    // return title;
  }, [order, title, overrideTitle, week, home]);

  return (
    <header className={className}>
      <LeftPart>
        {home && <HomeIcon onClick={goHome} />}
        {showBack && <BackIcon onClick={goBack} />}
      </LeftPart>

      <Title>{titleToRender}</Title>

      {
        !withoutBurger && (
          <Link href={`/settings/`} as={`/settings/`}>
            <Setting>
              <BurgerIcon />
            </Setting>
          </Link>
        )
      }
    </header>
  );
};

export default styled(Header)`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #054929;
  padding: 8px 20px;

  ${HomeIcon} {
    width: 22px;
  }

  ${BurgerIcon} {
    width: 26px;
  }
`;
